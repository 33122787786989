export const data = {
  panelImageName: "servicos-profissionais-bg.png",
  divisorText: "Profissionais Liberais",
  content: {
    imageName: "ProfissionaisLiberais.jpg",
    title: "Como ser nosso cliente?",
    text:
      "Se você trabalha como Profissional Liberal e quer proteger o seu negócio, daremos orientações primordiais para ajudar nas suas decisões.",
    options: [
      "Advogados",
      "Arqutetos",
      "Corretores",
      "Engenheiros",
      "Entre outros",
    ],
    contactText:
      "Tenha uma contabilidade personalizada, cadastre-se e receba nosso contato",
  },
}
