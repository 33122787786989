import React from "react";
import Image from "../../Image";
import { CheckCircle } from "@styled-icons/fa-solid/CheckCircle";
import PropTypes from "prop-types";
import { colors } from "../../../styles/colors";

import {
  ContentWrapper,
  Container,
  Content,
  ImageWrapper,
  ImageContainer,
  ContentTexts,
  ContentHeader,
  ContentText,
} from "./styles";
import {OptionContainer, OptionItem, Options} from "../../ContactPage/styles";

export default function BlankContentPage({ content }) {
  const { imageName, title, text, options } = content;

  return (
    <ContentWrapper>
      <Container>
        <Content>
          <ImageWrapper>
            <ImageContainer>
              <Image filename={imageName} />
            </ImageContainer>
          </ImageWrapper>
          <ContentTexts>
            <ContentHeader>{title}</ContentHeader>
            <ContentText>{text}</ContentText>
            <br />
            <Options>
              {options.map((item, idx) => (
                  <OptionContainer key={idx}>
                    <CheckCircle color={colors.checkColor} size={25} />
                    <OptionItem color={colors.secondFont}>{item}</OptionItem>
                  </OptionContainer>
              ))}
            </Options>
          </ContentTexts>
        </Content>
      </Container>
    </ContentWrapper>
  );
}

BlankContentPage.propTypes = {
  content: PropTypes.shape({
    imageName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    contactText: PropTypes.string.isRequired,
  }),
};
