import styled from "styled-components"
import { colors } from "../../../styles/colors"

export const PanelWrapper = styled.section`
  position: relative;
  max-height: 25rem;
  min-height: 35rem;
`

export const ImageWrapper = styled.div`
  div.gatsby-image-wrapper {
    max-height: 25rem;
    min-height: 35rem;
  }
`

export const PanelShadow = styled.div`
  top: 0;
  left: 0;
  background: #000;
  height: 100%;
  width: 100%;
  opacity: 60%;
  position: absolute;
`

export const PanelContent = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;

  top: 50%;
  bottom: 50%;

  left: 0;

  width: 100%;

  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const PanelSlogan = styled.strong`
  color: ${colors.mainFont};
  max-width: 60rem;
  font-size: 2.25rem;
  font-weight: normal;
  text-align: center;
  z-index: 1;
  margin: 0 auto;
  padding: 0 1rem;
`
