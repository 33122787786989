import React, { useState } from "react"
import PropTypes from "prop-types"
// import { toast } from "react-toastify"

// import api from "../../../services/api"

import {
  ContactWrapper,
  ContactText,
  ContactContent,
  InputName,
  InputEmail,
  Button,
  ContactFooter,
  InputContainer,
} from "./styles"

export default function SecondContact({ noHeader }) {
  // const [contact, setContact] = useState({
  //   name: "",
  //   email: "",
  // })

  // const handleGetContact = event => {
  //   setContact({
  //     ...contact,
  //     [event.target.name]: event.target.value,
  //   })
  // }

  // const handleClick = async () => {
  //   const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  //   if (emailPattern.test(contact.email)) {
  //     try {
  //       await api.post("/emails", contact)
  //       toast.success("Cadastro enviado com sucesso!")
  //       setContact({ name: "", email: "" })
  //     } catch (error) {
  //       toast.error("Erro ao enviar contato para cadastro...")
  //     }
  //   } else {
  //     toast.error("Verifique se digitou o e-mail corretamente...")
  //   }
  // }
  return (
    <ContactWrapper
      name="contact-register"
      action="/success-netlify"
      method="post"
      data-netlify="true"
      onSubmit="submit"
    >
      <input type="hidden" name="Contato Registro" value="contact-register"></input>
      <ContactText noHeader={noHeader}>
        Cadastre-se para se tornar nosso cliente
      </ContactText>
      <ContactContent>
        <InputContainer>
          <InputName
            name="name"
            placeholder="Nome"
          />
          <InputEmail
            name="email"
            placeholder="E-mail"
          />
        </InputContainer>
        <Button type="submit">Solicite sua Proposta</Button>
      </ContactContent>
      <ContactFooter>Não enviamos Spam!</ContactFooter>
    </ContactWrapper>
  )
}

SecondContact.defaultProps = {
  noHeader: false,
}

SecondContact.propTypes = {
  noHeader: PropTypes.bool,
}
