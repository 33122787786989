import React from "react"

import SEO from "../components/seo"

import Layout from "../components/Layout"
import BlankPage from "../components/BlankComponents/BlankPage"
import { data } from "../pagesContent/liberal"

const ProdissionaisLiberais = () => (
  <Layout>
    <SEO title="Profissionais Liberais" />
    <BlankPage data={data} />
  </Layout>
)

export default ProdissionaisLiberais
