import React from "react"
import PropTypes from "prop-types"

import Image from "../../Image"
import SecondContact from "../SecondContact"

import {
  PanelWrapper,
  ImageWrapper,
  PanelContent,
  PanelSlogan,
  PanelShadow,
} from "./styles"

export default function SecondPanel({ imageName }) {
  return (
    <PanelWrapper>
      <ImageWrapper>
        <Image filename={imageName} />
      </ImageWrapper>
      <PanelContent>
        <PanelSlogan>
          Mais que um serviço contábil, uma orientadora para o seu negócio
        </PanelSlogan>
        <SecondContact />
      </PanelContent>
      <PanelShadow />
    </PanelWrapper>
  )
}

SecondPanel.propTypes = {
  imageName: PropTypes.string.isRequired,
}
