import React from "react"
import PropTypes from "prop-types"

import SecondPanel from "../SecondPanel"
import PageDivisorHigher from "../../PageDivisorHigher"
import BlankContent from "../BlankContentPage"
import {colors} from "../../../styles/colors";

export default function BlankPage({ data }) {
  const { panelImageName, divisorText, content } = data

  return (
    <>
      <PageDivisorHigher notIndicator={true} hideSmall={true} text={divisorText}/>
      <BlankContent content={content} />
      <SecondPanel imageName={panelImageName} />
    </>
  )
}

BlankPage.propTypes = {
  data: PropTypes.shape({
    panelImageName: PropTypes.string.isRequired,
    divisorText: PropTypes.string.isRequired,
    content: PropTypes.object.isRequired,
  }),
}
