import React from "react"
import PropTypes from "prop-types"

import {
  DivisorWrapper,
  DivisorText,
  DivisorIndicator,
  DivisorContent,
} from "./styles"
import {colors} from "../../styles/colors";

export default function PageDivisor({ text, hideSmall, notIndicator, contentColor }) {
  return (
    <DivisorWrapper hideSmall={hideSmall}>
      <DivisorContent color={contentColor}>
        <DivisorText>{text}</DivisorText>
      </DivisorContent>
      <DivisorIndicator notIndicator={notIndicator} />
    </DivisorWrapper>
  )
}

PageDivisor.defaultProps = {
    hideSmall: false,
    notIndicator: false,
    contentColor: colors.second,
}

PageDivisor.propTypes = {
    text: PropTypes.string.isRequired,
    notIndicator: PropTypes.bool,
    hideSmall: PropTypes.bool,
    contentColor: PropTypes.string,
}
