import styled from "styled-components"
import { darken } from "polished"
import { colors } from "../../../styles/colors"

export const ContactWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 100%;
  margin-top: ${props => (props.noHeader ? "0" : "5rem")};
`

export const ContactText = styled.h2`
  display: ${props => props.noHeader && "none"};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: normal;
  color: ${colors.mainFont};
  text-align: center;
  margin-bottom: 0.62rem;
`

export const ContactContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`

export const InputName = styled.input`
  width: 100%;
  max-width: 21.6rem;
  margin-bottom: 0.62rem;
  margin-right: 0.62rem;
  padding: 1.06rem 0.62rem 1.06rem 0.94rem;
  border: ${props => (props.noHeader ? "1px solid #ccc" : 0)};
  border-radius: 5px;
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0.9);
  color: ${colors.inputFont};
`

export const InputEmail = styled.input`
  width: 100%;
  max-width: 21.6rem;
  margin-bottom: 0.62rem;
  margin-right: 0.62rem;
  padding: 1.06rem 0.62rem 1.06rem 0.94rem;
  border: ${props => (props.noHeader ? "1px solid #ccc" : 0)};
  border-radius: 5px;
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0.9);
  color: ${colors.inputFont};
`

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
`

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  width: 100%;
  max-width: 13.37rem;
  height: 3.25rem;
  border-radius: 6px;
  font-size: 1rem;
  color: ${colors.mainFont};
  background: ${colors.main};
  font-weight: 700;
  margin-bottom: 0.62rem;

  :hover {
    background: ${darken(0.02, `${colors.main}`)};
  }
`

export const ContactFooter = styled.span`
  margin: 6px;
  font-size: 0.75rem;
  color: ${colors.inputFont};
  font-weight: 500;
`
