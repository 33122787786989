import styled from "styled-components"
import media from "styled-media-query"
import { colors } from "../../../styles/colors"

export const ContentWrapper = styled.section`
  height: 80vh;
  display: flex;
  justify-content: center;
  background: ${colors.content};
  padding: 0 2rem;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 64rem;
  margin: 0 auto;
  padding-top: 0.62rem;
`

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

export const ImageWrapper = styled.div`
  margin-right: 3rem;

  ${media.lessThan("1091px")`
    margin: 0;
  `}
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 19.9rem;
  width: 19.9rem;
  background: ${colors.containerBackground};
  border-radius: 50%;

  ${media.lessThan("426px")`
    width: 18.25rem;
    height: 18.25rem;
  `}

  div.gatsby-image-wrapper {
    border-radius: 50%;
    height: 19.5rem;
    width: 19.5rem;

    ${media.lessThan("426px")`
      height: 17.5rem;
      width: 17.5rem;
    `}
  }
`

export const ContentTexts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ContentHeader = styled.h2`
  font-size: 26px;
  font-weight: bold;
  color: ${colors.secondFont};
  text-align: center;
  margin-top: 2rem;
`

export const ContentText = styled.p`
  font-size: 22px;
  font-style: normal;
  font-weight: normal;
  color: ${colors.secondFont};
  text-align: center;
  margin-top: 1rem;
  max-width: 40rem;
`

export const ContactContainer = styled.div`
  width: 100%;
`

export const ContactHeader = styled.h2`
  font-size: 22px;
  font-weight: bold;
  color: ${colors.secondFont};
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 0.5rem;
`
